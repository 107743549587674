export const STORAGE_KEY = {
  THREADS_PAGINATION: "threadsPagination",
  THREADS_CATEGORIES: "threadsCategories",
  THREADS_SCROLL_POSITION: "threadsScrollPosition",
  THREADS_REPLYING: "threadsReplying",
  NOTIFICATION_PERMISSION: "notificationPermission",
};

export const RXDB_KEY = {
  THREADS: "threads",
  THREADS_MESSAGES: "threadmessages",
};

export const THREADS_COLORS = ["rgb(236 218 244)", "rgb(222 248 248)"];

export const API = {
  url: `${process.env.API_URL}/api`,
  socketUrl: process.env.MESSAGING_API,
};

const isProduction = API.url.includes("-production");

export const DEFAULT_THREAD_CATEGORY = isProduction
  ? "65f6e56888ceda812237a0ca"
  : "6560b3d03f064b2079947f7e";
