import { signal } from "@lit-labs/preact-signals";

let fastRafCallbacks;
function fastRaf(callback) {
  if (!fastRafCallbacks) {
    fastRafCallbacks = [callback];

    requestAnimationFrame(() => {
      const currentCallbacks = fastRafCallbacks;
      fastRafCallbacks = undefined;
      currentCallbacks.forEach((cb) => cb());
    });
  } else {
    fastRafCallbacks.push(callback);
  }
}

function doubleRaf() {
  return new Promise((resolve) => {
    fastRaf(() => {
      fastRaf(resolve);
    });
  });
}

export function fixSafariStickyInput(input) {
  input.style.transform = "translateY(-99999px)";
  input.focus();

  doubleRaf().then(() => {
    input.style.transform = "";
  });
}

function findUpClassName(el, className) {
  return el.closest("." + className);
}

export function blurActiveElement() {
  if (document.activeElement?.blur) {
    document.activeElement.blur();
    return true;
  }

  return false;
}

export function blockScrollMove(scrollabeClass = "scrollable-y", doc = null) {
  if (!doc) {
    doc = document;
  }

  const key = "clientY";
  const o = { capture: true, passive: false };
  let startY = 0;

  doc.addEventListener("touchmove", onTouchMove, o);
  doc.addEventListener("touchstart", onTouchStart);

  return () => {
    doc.removeEventListener("touchmove", onTouchMove, o);
    doc.removeEventListener("touchstart", onTouchStart);
  };

  function onTouchMove(e) {
    const touch = e.touches[0];
    let shadowScrollableElement;

    //check if touch event is coming from shadow dom
    if (touch.target.shadowRoot) {
      shadowScrollableElement = touch.target.shadowRoot.activeElement;
    }

    const scrollable = findUpClassName(
      shadowScrollableElement ? shadowScrollableElement : touch.target,
      scrollabeClass
    );
    if (scrollable) {
      const y = touch[key];
      const scrolled = startY - y;

      const scrollTop = scrollable.scrollTop;
      const scrollHeight = scrollable.scrollHeight;
      const clientHeight = scrollable.clientHeight;
      const nextScrollTop = scrollTop
        ? Math.round(scrollTop + scrollable.clientHeight + scrolled)
        : scrollTop + scrolled;
      const needCancel =
        scrollHeight === clientHeight ||
        nextScrollTop >= scrollHeight ||
        nextScrollTop <= 0;
      if (needCancel) {
        if (e.cancelable) e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }

  function onTouchStart(e) {
    if (e.touches.length > 1) return;
    const touchStart = e.touches[0];

    startY = touchStart[key];
  }
}

function isActiveElementAnInput(doc = null) {
  if (!doc) {
    doc = document;
  }

  if (!doc.activeElement) return false;

  if (doc.activeElement.shadowRoot) {
    return isActiveElementAnInput(doc.activeElement.shadowRoot);
  }

  return (
    doc.activeElement.tagName === "INPUT" ||
    doc.activeElement.tagName === "TEXTAREA"
  );
}

export function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

export const isKeyboardOpen = signal(false);

export function handleVirtualKeyboardState({
  onOpen = () => {},
  onClose = () => {},
  onVHChange = () => {},
  onDisconnect = () => {},
} = {}) {
  /* This function should work only on touch devices that have a virtual keyboard, so we can return early if the device is not a touch device. */
  if (!isTouchDevice()) {
    return () => {};
  }

  let lastVH;

  const setViewportHeight = () => {
    let viewportHeight =
      (window.visualViewport.height || window.visualViewport.innerHeight) *
      0.01;
    viewportHeight = +viewportHeight.toFixed(2);

    if (lastVH === viewportHeight) {
      return;
    }

    lastVH = viewportHeight;
  };

  const handleResize = () => {
    const beforeHeight = lastVH;

    setViewportHeight();
    onVHChange(lastVH);

    /* 
      We don't want to call onClose if the keyboard is not open.
    */
    if (beforeHeight && beforeHeight < lastVH && isKeyboardOpen.value) {
      onClose(lastVH);
      isKeyboardOpen.value = false;
      return;
    }

    /*
      We don't want to call onOpen if the resize just happened because of a change in the window size
      but without an input element being focused.
    */
    if (beforeHeight && beforeHeight > lastVH && isActiveElementAnInput()) {
      onOpen(lastVH);
      isKeyboardOpen.value = true;
      return;
    }
  };

  handleResize();

  window.visualViewport.addEventListener("resize", handleResize);

  return () => {
    window.visualViewport.removeEventListener("resize", handleResize);
    onDisconnect();
  };
}
