import { authStore } from "../../../utilities/authStore";
import { AI_PROGRAMME_ID } from "./const";
import { blurActiveElement, handleVirtualKeyboardState } from "./keyboard";

let scrollObj = {};

export function getThreadCategoryId(thread) {
  if (!thread) return null;
  return typeof thread.category === "string"
    ? thread.category
    : thread.category?.id;
}

export function getScrollPosition(categoryId) {
  if (!categoryId) categoryId = "_all_";
  return scrollObj[categoryId] ?? null;
}

export function setScrollPosition(scrollY, categoryId) {
  if (!categoryId) categoryId = "_all_";
  scrollObj[categoryId] = scrollY;
}

export function resetScrollPositions() {
  scrollObj = {};
}

export function checkIfAIProgramme() {
  return authStore.programmeId.value === AI_PROGRAMME_ID;
}

export function adjustTextareaHeight(
  inputElement = "thread-reply-input",
  returnHeight = false
) {
  let textArea = document.getElementById(inputElement);

  if (textArea) {
    textArea.style.height = "auto";
    textArea.style.height = `${textArea.scrollHeight}px`;

    if (returnHeight) {
      return `height: ${textArea.scrollHeight}px`;
    }
  }
}

export function setTextareaHeight(textArea = null) {
  if (!textArea) return null;
  textArea.style.height = "auto";
  if (textArea.scrollHeight <= textArea.offsetHeight) return;
  textArea.style.height = `${textArea.scrollHeight}px`;
}

export function getAnchorElement(element, index = 0) {
  if (index > 4) return null;

  if (element.tagName === "A") return element;

  if (!element.parentElement) return null;

  return getAnchorElement(element.parentElement, index + 1);
}

export function scrollToThread(
  threadId,
  { toInput = false, block = "nearest" } = {}
) {
  const threadsPageElement = document.querySelector("threads-page");
  const threadsItemListElement =
    threadsPageElement?.shadowRoot?.querySelector("threads-item-list");
  const threadElement =
    threadsItemListElement.shadowRoot.getElementById(threadId);

  if (!threadElement) return;

  if (toInput) {
    const inputElement = threadElement.querySelector("input");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }
  }

  threadElement.scrollIntoView({ behavior: "smooth", block });
}

export function checkIfSuperAdmin() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const testParam = urlSearchParams.get("test");
  return testParam === "1";
}

export function addKeyboardCheck() {
  const blockScrollFunction = () => window.scrollTo(0, 0);

  return handleVirtualKeyboardState({
    onOpen: () => {
      document.addEventListener("scroll", blockScrollFunction);
    },
    onClose: () => {
      blurActiveElement();
      document.removeEventListener("scroll", blockScrollFunction);
    },
    onVHChange: (vh) => {
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  });
}
