import qs from "qs";
import { Router } from "@vaadin/router";
import { authStore } from "../../authStore";
import { THREADS_COLORS } from "./chatsStore.const";
import { THREAD_EVENTS_CATEGORY_ID } from "../../../pages/threads/utils/const";
import {
  checkIfAIProgramme,
  getThreadCategoryId,
} from "../../../pages/threads/utils/helpers";

export function parseToJson(stringifyObj = "", defaultValue = {}) {
  try {
    return JSON.parse(stringifyObj);
  } catch (error) {
    return defaultValue;
  }
}

/**
 * Retrieves an item from the local storage based on the provided key.
 * Useful specifically for retrieving objects or arrays from local storage.
 *
 * @param {string} key - The key of the item to retrieve from local storage.
 * @param {string} defaultValue - The default value to return if the item is not found in local storage.
 * @returns {any} The retrieved item from local storage, or the default value if the item is not found.
 */
export function getItem(key = "", defaultValue = "") {
  if (!key) return console.error("Key is missing");

  return parseToJson(localStorage.getItem(key) ?? defaultValue, defaultValue);
}

export function setItem(key = "", value = "") {
  if (!key || !value) return console.error("Key or value is missing");

  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting item "${key}" in local storage`, error);
  }
}

export async function setRXItem(key = "", values = []) {
  // return cohortDb.then((db) => db.rxdb[key].bulkUpsert(values));
}

export async function removeRXItems(key = "", ids = []) {
  // return cohortDb.then((db) => db.rxdb[key].bulkRemove(ids));
}

export async function getRXItem(key = "") {
  return [];
  // const items = await cohortDb.then((db) => db.rxdb[key].find().exec());

  // return items.map((item) => item.toJSON());
}

export function getRandomColor() {
  return THREADS_COLORS[Math.floor(Math.random() * THREADS_COLORS.length)];
}

export function copyObj(obj = {}) {
  return JSON.parse(JSON.stringify(obj));
}

export function generateUsersAndFounders(items = []) {
  const generatedUsersAndFounders = new Map();

  items.forEach((item, i) => {
    if (item?.founder) {
      addEntityToUsersAndFounders(
        item.founder,
        getUrlFromImage(item.founder.picture || item.founder.image)
      );
    } else if (item?.user) {
      addEntityToUsersAndFounders(item.user, getUserImageUrl(item.user));
    } else {
      console.error("No founder or user found in participant", item, i);
    }
  });

  addCurrentUser();

  return generatedUsersAndFounders;

  function addEntityToUsersAndFounders(entity, imageUrl) {
    if (typeof entity === "string") {
      entity = { id: entity };
    }

    const initials = (entity.name || "")
      .split(" ")
      .map((n) => n[0])
      .join(".");

    generatedUsersAndFounders.set(entity.id, {
      id: entity.id,
      displayName: entity.name || entity.email || entity.id || undefined,
      initials,
      imageUrl,
    });
  }

  function getUrlFromImage(image) {
    return (
      image?.sizes?.thumbnail?.url ||
      image?.url ||
      (typeof image === "string" && image) ||
      ""
    );
  }

  function getUserImageUrl(user) {
    if (user.role === "startup") return getUrlFromImage(user.startup?.logo);
    if (user.role === "admin") return getUrlFromImage(user.admin?.image);
    if (user.role === "mentor") return getUrlFromImage(user.mentor?.image);
  }

  function addCurrentUser() {
    if (authStore.founderId.value && authStore.founderObject.value) {
      addEntityToUsersAndFounders(
        authStore.founderObject.value,
        getUrlFromImage(
          authStore.founderObject.value.picture ||
            authStore.founderObject.value.image
        )
      );
    }

    if (authStore.userObject.value) {
      addEntityToUsersAndFounders(
        authStore.userObject.value,
        getUserImageUrl(authStore.userObject.value)
      );
    }
  }
}

export function stringifyQuery(query, xOptions = {}) {
  return qs.stringify(
    { where: query, depth: 0, ...xOptions },
    { addQueryPrefix: true }
  );
}

export function getDMChatLink(chatId = "") {
  return `/inbox/${chatId}`;
}

export function navigateToChat(chatId = "") {
  Router.go(getDMChatLink(chatId));
}

export function navigateToThread(threadId = "", forceNavigate = false) {
  const path = threadId ? `/threads/${threadId}` : "/threads";

  if (threadId && location.pathname.startsWith("/threads") && !forceNavigate) {
    const threadElement = document.getElementById(threadId);
    if (threadElement) {
      threadElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      return;
    }
  }

  Router.go(path);
}

export function getDmsChatsV(items = []) {
  return items.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
}

export function waitFor(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function supportServiceWorker() {
  return "serviceWorker" in navigator;
}

export function supportPushNotification() {
  return "PushManager" in window && "Notification" in window;
}

export function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function playAudio(src = "") {
  if (!src) return console.log("No audio source");

  const audio = new Audio(src);

  audio.play().catch((error) => {
    console.error("Error playing audio", error);
  });
}

export async function isAppInstalled() {
  // Maybe implement this in the future when navigator.getInstalledRelatedApps is stable?
  // see: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/getInstalledRelatedApps
  return false;
}

export function getChatRequestQueryAndBody(toUserId, toFounderId) {
  const meUserId = authStore.userId.value;
  const meFounderId = authStore.founderId.value ?? "";

  const body = {
    programmeId: authStore.programmeId?.value,
    cohortId: authStore.cohort.value[0]?.id,
  };
  const query = { and: [] };
  const meAnd = { and: [] };
  const toAnd = { and: [] };
  const cohortAnd = {
    and: [
      {
        cohort: { equals: authStore.cohort.value[0]?.id },
      },
    ],
  };

  if (meUserId) addId(meAnd, meUserId, "userId", "user");
  if (meFounderId) addId(meAnd, meFounderId, "founderId", "founder");

  if (toUserId) addId(toAnd, toUserId, "toUserId", "user");
  if (toFounderId) addId(toAnd, toFounderId, "toFounderId", "founder");

  query.and.push(meAnd, toAnd, cohortAnd);

  return { query, body };

  function addId(objAnd, id, bodyField, queryField) {
    body[bodyField] = id;
    objAnd.and.push({ [`participants.${queryField}`]: { equals: id } });
  }
}

export function executePromise(promise) {
  return promise
    .then((response) => response.json())
    .then((data) => ({ error: null, data }))
    .catch((error) => ({ error, data: null }));
}

export function getThreadsItemsV(items, selectedCategoryId) {
  if (selectedCategoryId) {
    const checkSameCategory = (thread) =>
      getThreadCategoryId(thread) === selectedCategoryId;

    if (
      checkIfAIProgramme() &&
      selectedCategoryId === THREAD_EVENTS_CATEGORY_ID
    ) {
      items = items.filter(
        (thread) => checkSameCategory(thread) && !!thread.event
      );
    } else {
      items = items.filter((thread) => checkSameCategory(thread));
    }
  }

  return items;
}
